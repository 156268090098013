import React, { useEffect, useState } from "react";
import { isAndroid, isIOS } from "react-device-detect";
import { useParams } from "react-router-dom";
import { POOL } from "../../pool";
import axiom from "../../utils/axiom";
import { useDetailsHook } from "../details/hook";

export const Redirector = () => {
  const [error, setError] = useState(null);
  const { short_code } = useParams();

  const { detailsOfUrl } = useDetailsHook(short_code);

  // const utm_data = useUTM();

  const log_click = async () => {
    let url = detailsOfUrl.url;
    // if (window?.location?.search) {
    //   url = detailsOfUrl.url + window.location.search;
    // } else {
    //   url = detailsOfUrl.url;
    // }

    // if (search) {
    //   url = `${url}${search}`
    // } 
    // console.info(url)

    // Next time capture user ips and other location data and send here
    // Also make sure to not log a click from the same ip twice
    await axiom.post(POOL.base + POOL.paths.click, {
      shortCode: short_code,
      ip_address: "",
      browser: "",
      url,
      // utm_data,
    });

    console.log("isNonStandardUrl", isNonStandardUrl(url));
    if (isNonStandardUrl(url)) {
      openApp(url, detailsOfUrl);
    } else {
      window.location.href = url;
    }
  };

  function isNonStandardUrl(url) {
    const regex = /:\/\//; // Matches "://"
    const isStandardProtocol = /^https?/; // Matches "http" or "https" at the beginning

    return regex.test(url) && !isStandardProtocol.test(url);
  }

  const openApp = async (appUrl, details) => {
    try {
      window.location.href = appUrl;
    } catch (error) {
      console.error("error.message", error);
      setError(error.message);
      if (
        error.name === "AbortError" ||
        error.message.includes("scheme not registered")
      ) {
        console.error("Error launching URL:", error);
        // Redirect to fallback URL (optional)
      } else {
        // Handle other potential errors
        console.error("Unexpected error:", error);
      }
    }

    setTimeout(() => {
      let fallback = "";

      if (isAndroid) fallback = details.fallbacks.android;
      else if (isIOS) fallback = details.fallbacks.iOS;
      else fallback = details.fallbacks.web;

      window.location.href = fallback;
    }, 3000);
  };

  useEffect(() => {
    console.log('window.location', window.location)
    if (!detailsOfUrl) return;
    log_click();
  }, [detailsOfUrl]);

  return (
    <div>
      <span style={{ fontSize: 12, padding: 20 }}>Redirecting you...</span>
      <br />
      {error && error}
    </div>
  );
};
