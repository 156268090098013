
const environments = {
  production: {
    baseUrl: "https://fxurl.co/v1/"
  },
  staging: {
    baseUrl: "https://dev.fxurl.co/v1/"
  },
  local: {
    baseUrl: "http://localhost:8001/v1/"
  },
}

export const POOL = {
  base: environments.production.baseUrl,
  paths: {
    createShortUrl: "shorten",
    listShortUrls: "shorten/list",
    getDetails: "shorten/details",
    deleteDetails: "shorten/delete",
    click: "shorten/click",
    updateDetails:'shorten/update',
    getClickStatsByInitialShortCode:"shorten/click-stats-list/"
  },
};

export const USER = {
  base: environments.production.baseUrl,
  paths: {
    loginUser: "users/login",
    createClient: "users/register",
    getClient: "users/client-details/",
    listOfClients:'users',
    listOfAllUserList:'users/get-user-list',
    updateClientDetails:"users/update",
    updatePassword:"users/change-password",
    getclietsByOperational:"users/get-clients-by-operational",
    approveUser:'users/approve-user',
    deleteUser:'users/delete',
    getWhatsappUrlData:'users/get-whatspp-url-data'
  },
};

export const CONVERSION = {
  base: environments.production.baseUrl,
  paths: {
    createConversionCount: "conversion/add-conversion-count",
    getTodayConversionCount: "conversion/get-today-conversion-count",
  },
};

