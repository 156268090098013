import ErrorPage from "./error-page";
import CreateClientSCreen from "./feature/Admin/Client/CreateClientSCreen";
import ClientListScreen from "./feature/Admin/List/ClientListScreen";
import OperationalUserDetails from "./feature/Admin/List/OperationalUserDetails";
import PrivateRoute from "./feature/Auth/ProtectedRoute";
import { CreateShortUrlScreen } from "./feature/create/CreateScreen";
import { ViewShortUrl } from "./feature/details/DetailsScreen";
import AllDataScreen from "./feature/list/AllDataScreen";
import { ListShortUrls, defaultUrlListLoader } from "./feature/list/ListScreen";
import LoginScreen from "./feature/Login/LoginScreen";
import ProfileScreen from "./feature/Profile/ProfileScreen";
import { Redirector } from "./feature/redirector/Redirector";
import CreateClientScreen from './feature/User/Client/CreateClientScreen';
import CreateOperationalScreen from "./feature/User/Operational/CreateOperationalScreen";
import WhatsappStatsScreen from "./feature/Whatsapp/WhatsappStatsScreen";
import PageNotFound from "./PageNotFound";
import { WrongPlace } from "./wrong-place";

const routes_no_session = [

  {
    path: "/login",
    element: <LoginScreen />,
    errorElement: <ErrorPage />,
    name: "Create",
    icon: '💧'
  },
  {
    path: "/error",
    element: <PageNotFound />,
    errorElement: <ErrorPage />,
    name: "Create",
    icon: '💧'
  },
  {
    path: "/create-client",
    element: <CreateClientScreen />,
    errorElement: <ErrorPage />,
    name: "Create Client",
  },
  {
    path: "/create-operational",
    element: <CreateOperationalScreen />,
    errorElement: <ErrorPage />,
    name: "Create Operational",
    icon: '💧'
  },
  {
    hidden: true,
    path: "/",
    element: <WrongPlace />,
    errorElement: <ErrorPage />,
    name: "Error",
    icon: '🚫'
  },
  {
    path: "/create",
    element: <PrivateRoute><CreateShortUrlScreen /></PrivateRoute>,
    errorElement: <ErrorPage />,
    name: "Create",
    icon: '💧'
  },
  {
    path: "/list",
    element: <PrivateRoute><ListShortUrls /></PrivateRoute>,
    errorElement: <ErrorPage />,
    loader: defaultUrlListLoader,
    name: "List",
    icon: '🦊'
  },
  {
    hidden: true,
    path: "/lists",
    element: <PrivateRoute><AllDataScreen /></PrivateRoute>,
    errorElement: <ErrorPage />,
    name: "List",
    icon: '📃'
  },
  {
    hidden: true,
    path: "/list/view/:short_code",
    element: <PrivateRoute><ViewShortUrl /></PrivateRoute>,
    errorElement: <ErrorPage />,
    name: "List",
    icon: '😸'
  },
  {
    hidden: true,
    path: "/:short_code",
    element: <Redirector />,
    errorElement: <ErrorPage />,
    name: "Redirector",
    icon: '😸'
  },
  {
    hidden: true,
    path: "/:client_id/:short_code",
    element: <Redirector />,
    errorElement: <ErrorPage />,
    name: "Redirector",
    icon: '😸'
  },
  {
    hidden: true,
    path: "/",
    element: <PrivateRoute><WrongPlace /></PrivateRoute>,
    errorElement: <div>Wrong place</div>,
    name: "Error",
    icon: '🚫'
  },

  // admin Routes 
  {
    path: "/create-client",
    element: <PrivateRoute><CreateClientSCreen /></PrivateRoute>,
    errorElement: <ErrorPage />,
    name: "Create Client",
    icon: '🚫'
  },
  {
    path: "/user-list",
    element: <PrivateRoute><ClientListScreen /></PrivateRoute>,
    errorElement: <ErrorPage />,
    name: "Create Client",
    icon: '🚫'
  },
  {
    path: "/profile",
    element: <PrivateRoute><ProfileScreen /></PrivateRoute>,
    errorElement: <ErrorPage />,
    name: "Profile",
    icon: '👤'
  },
  {
    hidden: true,
    path: "/operational-user/:id",
    element: <PrivateRoute><OperationalUserDetails /></PrivateRoute>,
    name: "Operational",
  },
  {
    path: "/whatsapp",
    element: <PrivateRoute><WhatsappStatsScreen /></PrivateRoute>,
    name: "Whatsapp"
  }
]

export default routes_no_session;